// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAUNPpGuLXzgCej4uOns8WOR0bqa-ZdhqA",
  authDomain: "dezign-shoppe.firebaseapp.com",
  projectId: "dezign-shoppe",
  storageBucket: "dezign-shoppe.appspot.com",
  messagingSenderId: "392865192717",
  appId: "1:392865192717:web:08c5b0c34ff44b9c64ae44",
  measurementId: "G-KH87HQ0Q8V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

import "./css/styles.scss";
import './jquery_modules';
import { gsap } from "gsap";

const tl = gsap.timeline({repeat: -1, repeatDelay: 1});
const img = $(".background_img");
const phone_query = window.matchMedia("(max-width:900px)");

tl.to(img,{
    x:"-40%",
    duration:40
}).to(img,{
    x:0,
    duration:40
})

//media queries
if(phone_query.matches){
    tl.kill()
    const tl2 = gsap.timeline({repeat: -1, repeatDelay: 2});
    tl2.to(img,{
        "background-position-x":"80%",
        duration:50
    }).to(img,{
        "background-position-x":"0%",
        duration:50
    })
}